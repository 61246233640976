import BasePlugin from '../BasePlugin'

export default class AddAdjustmentRequestByScenarioCommand extends BasePlugin {
  async execute () {
    let scenarioId = {
      'scenarioId': this.context.getModel().id
    }
    this.context.getCard().setLoading(true)
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddAdjustmentRequestByScenarioCommand`,
      scenarioId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      let adjustmentRequestId = {
        'adjustmentRequestId': response.data.adjustment_request_id,
        'async': 'true'
      }
      this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddAdjustmentRequestCommand`,
        adjustmentRequestId,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        this.context.getCard().setLoading(false)
        this.context.$msgbox({
          type: 'info',
          message: 'Задача на добавление заявки на корректировку поставлена в очередь. По завершении вы получите уведомление'
        })
      })
    })
  }
}
